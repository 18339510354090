







import { NoDataFoundComponent } from "@/commoncomponents/nodatafoundcomponent/NoDataFoundComponent";
export default NoDataFoundComponent;
