import { Vue, Component,Prop } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText';

@Component
export class NoDataFoundComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    viewData!:string
 
    @Prop()
    viewClass!:string

    @Prop()
    mobileViewResponse!:boolean

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }


}
